import { configureStore } from "@reduxjs/toolkit";
import checklistReducer from "redux/slices/checklistSlice";
import currentUserReducer from "redux/slices/currentUserSlice";
import suggestionReducer from "redux/slices/suggestionSlice";

export const store = configureStore({
  reducer: {
    checklist: checklistReducer,
    currentUser: currentUserReducer,
    suggestion: suggestionReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
