import { createSlice } from "@reduxjs/toolkit";
import { CurrentUser } from "types";

export interface CurrentUserState {
  value: CurrentUser;
}

declare const window: {
  currentUser: CurrentUser;
} & Window;

const initialState: CurrentUserState = {
  value: window.currentUser,
};

export const currentUserSlice = createSlice({
  name: "currentUser",
  initialState,
  reducers: {},
});

export default currentUserSlice.reducer;
