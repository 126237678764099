import { createSlice } from "@reduxjs/toolkit";
import { Suggestion } from "types";

export interface SuggestionState {
  value: Suggestion;
}

const initialState: SuggestionState = {
  value: {
    isSuggestionMode: false,
    suggestionId: undefined,
  },
};

const suggestionSlice = createSlice({
  name: "suggestion",
  initialState,
  reducers: {
    enableSuggestionMode(state) {
      state.value.suggestionId = crypto.randomUUID();
      state.value.isSuggestionMode = true;
    },
    disableSuggestionMode(state) {
      state.value.suggestionId = undefined;
      state.value.isSuggestionMode = false;
    },
  },
});

export const { enableSuggestionMode, disableSuggestionMode } =
  suggestionSlice.actions;
export default suggestionSlice.reducer;
